<template>
  <div>
    <img class="img__body" :src="imgBody" alt="" />
    <div class="container__logo">
      <img class="logo__clear" :src="imgLogo" alt="" />
    </div>
    <div class="container_formulario__registro">
      <form
        class="form d-flex align-items-center py-5 px-4 px-md-0"
        @submit.prevent="handleSubmit"
      >
        <div class="col-12 col-md-8 mx-auto ">
          <div class="row ">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Nombre</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Nombre"
                  v-model="candidate.name"
                  @blur="$v.candidate.name.$touch()"
                />
                <small
                  class="form-text text-danger error_label"
                  v-if="nameErrors[0]"
                  >{{ nameErrors[0] }}</small
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Apellido</label>
                <input
                  type="text"
                  placeholder="Apellido"
                  v-model="candidate.lastname"
                  @blur="$v.candidate.lastname.$touch()"
                />
                <small
                  class="form-text text-danger error_label"
                  v-if="lastnameErrors[0]"
                  >{{ lastnameErrors[0] }}</small
                >
              </div>
            </div>
          </div>

          <div class="row ">
            <div class="col-md-12">
              <div class="form-group">
                <label for="name">Cargo al que postula</label>
                <input
                  type="text"
                  placeholder="Cargo al que postula"
                  v-model="candidate.positionApply"
                  @blur="$v.candidate.positionApply.$touch()"
                />
                <small
                  class="form-text text-danger error_label"
                  v-if="positionApplyErrors[0]"
                  >{{ positionApplyErrors[0] }}</small
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Correo electrónico</label>
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  v-model="candidate.email"
                  @blur="$v.candidate.email.$touch()"
                />
                <small
                  class="form-text text-danger error_label"
                  v-if="emailErrors[0]"
                  >{{ emailErrors[0] }}</small
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Número de teléfono</label>
                <input
                  type="number"
                  placeholder="Número de teléfono"
                  v-model="candidate.phonenumber"
                  @blur="$v.candidate.phonenumber.$touch()"
                />
                <small
                  class="form-text text-danger error_label"
                  v-if="phonenumberErrors[0]"
                  >{{ phonenumberErrors[0] }}</small
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="name">Nivel de estudios</label>
                <select
                  id="estudios"
                  v-model="candidate.levelStudyId"
                  @blur="$v.candidate.levelStudyId.$touch()"
                >
                  <option selected value="" disabled
                    >Selecciona una opción</option
                  >
                  <option value="1">Enseñanza básica</option>
                  <option value="2">Enseñanza media</option>
                  <option value="3">Universitaria</option>
                </select>
                <small
                  class="form-text text-danger error_label"
                  v-if="levelStudyErrors[0]"
                  >{{ levelStudyErrors[0] }}</small
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Sexo</label>
                <div>
                  <input
                    type="radio"
                    name="sexo"
                    id="sexo-masculino"
                    v-model="candidate.sexo"
                    @blur="$v.candidate.sexo.$touch()"
                    value="m"
                  />
                  <label for="sexo-masculino" class="radio-label"
                    >Masculino</label
                  >
                </div>
                <div>
                  <input
                    type="radio"
                    name="sexo"
                    id="sexo-femenino"
                    v-model="candidate.sexo"
                    @blur="$v.candidate.sexo.$touch()"
                    value="f"
                  />
                  <label for="sexo-femenino" class="radio-label"
                    >Femenino</label
                  >
                </div>
                <small
                  class="form-text text-danger error_label"
                  v-if="sexoErrors[0]"
                  >{{ sexoErrors[0] }}</small
                >
              </div>
            </div>
          </div>

          <div class="row justify-content-center pt-4">
            <div class="col-5">
              <button
                class="btn__comenzar"
                type="submit"
                :disabled="invalidForm"
              >
                CONTINUAR
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import Candidate from "../models/Candidate";

export default {
  mixins: [validationMixin],
  data() {
    return {
      imgBody: require("@/assets/img/clear people sin letras.png"),
      imgLogo: require("@/assets/img/clear people naranja (1).png"),
      candidate: new Candidate("", "", "", "", "", "", "", "", "", ""),
    };
  },
  methods: {
    handleSubmit() {
      this.$router.push("/description");
    },
  },
  beforeDestroy() {
    this.$store.dispatch("Quiz/addCandidate", this.candidate);
  },
  computed: {
    invalidForm() {
      return this.$v.candidate.$invalid;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.candidate.name.$dirty) return errors;
      !this.$v.candidate.name.required && errors.push("Campo requerido");
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.candidate.lastname.$dirty) return errors;
      !this.$v.candidate.lastname.required && errors.push("Campo requerido");
      return errors;
    },
    positionApplyErrors() {
      const errors = [];
      if (!this.$v.candidate.positionApply.$dirty) return errors;
      !this.$v.candidate.positionApply.required &&
        errors.push("Campo requerido");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.candidate.email.$dirty) return errors;
      !this.$v.candidate.email.required && errors.push("Campo requerido");
      !this.$v.candidate.email.email && errors.push("Email invalido");
      return errors;
    },
    levelStudyErrors() {
      const errors = [];
      if (!this.$v.candidate.levelStudyId.$dirty) return errors;
      !this.$v.candidate.levelStudyId.required &&
        errors.push("Campo requerido");
      return errors;
    },
    phonenumberErrors() {
      const errors = [];
      if (!this.$v.candidate.phonenumber.$dirty) return errors;
      !this.$v.candidate.phonenumber.required && errors.push("Campo requerido");
      return errors;
    },
    sexoErrors() {
      const errors = [];
      if (!this.$v.candidate.sexo.$dirty) return errors;
      !this.$v.candidate.sexo.required && errors.push("Campo requerido");
      return errors;
    },
  },
  validations: {
    candidate: {
      name: {
        required,
      },
      lastname: {
        required,
      },
      positionApply: {
        required,
      },
      email: {
        required,
        email,
      },
      phonenumber: {
        required,
      },
      levelStudyId: {
        required,
      },
      career: {
        required: (value, vm) => {
          if (vm.levelStudyId === 3) {
            return value.length !== 0 ? true : false;
          } else {
            return true;
          }
        },
      },
      sexo: {
        required,
      },
    },
  },
};
</script>

<style scoped src="bootstrap/dist/css/bootstrap.min.css"></style>
<style>
.form {
  max-width: 800px;
}

.form-group label {
  color: #999999;
  font-weight: 200;
  font-size: 14px;
}

.vld-overlay .vld-icon,
.vld-parent {
  text-align: center;
}
</style>

<style scoped>
/* inicio - css radio checkbox */
/* Basic styles */
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.radio-label {
  position: relative;
  display: inline-block;
  padding: 3px 0 0 2.2em;
  line-height: 1.5;
  cursor: pointer;
  font-size: 13px;
  color: #999999;
}
.radio-label::before,
.radio-label::after {
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
.radio-label::before {
  content: " ";
  border: 2px solid #bdc3c7;
  border-radius: 20%;
}
/* Checkbox */
input[type="checkbox"] + .radio-label::after {
  content: "\2714";
  color: #fff;
  line-height: 1.6;
  padding-left: 2px;
  padding-top: 2px;
  text-align: center;
}
/* Radio */
input[type="radio"] + .radio-label::before {
  border-radius: 50%;
}
input[type="radio"] + .radio-label::after {
  content: " ";
  top: 0.29em;
  left: 0.29em;
  width: 1em;
  height: 1em;
  background: #d95f1f;
  border: 0.2em solid #fff;
  border-radius: 50%;
}
/* :checked */
input[type="checkbox"]:checked + .radio-label::before,
input[type="radio"]:checked + .radio-label::before {
  background: #d95f1f;
  border-color: #d95f1f;
}
input[type="checkbox"] + .radio-label::after,
input[type="radio"] + .radio-label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
input[type="checkbox"]:checked + .radio-label::after,
input[type="radio"]:checked + .radio-label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/* Transition */
.radio-label::before,
.radio-label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* fin - css radio checkbox */
</style>
